import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { PLACES, SALEM, popular_cities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/site-settings';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  places = PLACES;
  cars = [];
  popularCities = popular_cities;
  settings = SITE_SETTINGS;
  cities = SALEM;

  constructor(private taxiSerice: OutstationService) { }

  ngOnInit(): void {
    this.taxiSerice.tariff.subscribe(res => {
      if (res) {
        this.cars = res;
        this.cars = this.cars.sort((a, b) => a.oneway - b.oneway);
        this.getRouteEstimation();
      }
    })
  }

  getRouteEstimation() {
    this.cities.map(route => {
      this.cars.forEach(car => {
        route[`${car.carType}`] = Math.round(car.oneway * route.distance + car.onewayDriverFee);
      });  
    });
  }

}
