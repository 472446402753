import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { SITE_SETTINGS } from 'src/app/site-settings';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {
  settings = SITE_SETTINGS;
  cars;
  constructor(private taxiSerice: OutstationService) { }

  ngOnInit(): void {
    this.taxiSerice.tariff.subscribe(res => {
      if (res) {
        this.cars = res;
      }
    })
  }
}
