<!-- BANNER START -->
<div id="booking" class="wo-mainbanner-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="wo-banner">
          <div class="wo-banner__formwrap">

            <div class="wo-bannertabs-wrap tab-content">
              <div class="wo-bannertabs-content tab-pane active fade show" id="wt-skills">

                <app-outstation></app-outstation>
              </div>
              
            </div>
          </div>


          <div id="wo-banner__silder" class="wo-banner__silder">
            <figure>
              <img src="assets/bg.jpg" alt="img description">
              <figcaption class="wo-slidercontent">
                <div class="wo-slidercontent__title">
                  <h1>About<span>{{settings.siteName}}</span>
                  </h1>

                </div>
                <p>At Drop Taxi Salem, our mission is to redefine the way you travel. We aim to provide a convenient,
                  reliable, and cost-effective alternative to traditional transportation methods. Whether you're a solo
                  traveler, a family, or a group, we want to be your first choice for getting to your destination.





                </p>
                <p>
                  We are just a call or click away from making your travel experience unforgettable. Reach out to us
                  today to book your next journey with Drop Taxi Salem. We look forward to being your trusted partner in
                  travel, ensuring you reach your destination comfortably and on time.






                </p>
              
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- BANNER END -->


<section class="about type_one">
  <div class="container">
    <div class="row">

      <div class="col-lg-6 mt-5">
        <div class="heading tp_one  icon_dark">

          <h3 class="mb-3 text-center">Drop Taxi Tariff
          </h3>
        </div>
        <div class="about_content">
          <table style="width:100%" colspan="2">
            <tbody>
              <tr>
                <th>Vehicle Type</th>
                <th>One Way</th>
                <th>Round Trip</th>
              </tr>
              <tr *ngFor="let car of cars">
                <td>{{car.carType}}</td>
                <td>Rs.{{car.oneway}}/KM</td>
                <td>Rs.{{car.roundtrip}}/KM</td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="about type_one">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="heading tp_one  icon_dark">
          <h3 class="mb-3 text-center">Points before ur travel
          </h3>

        </div>
        <div class="about_content">
          <h4> Any Sedan</h4>
          <p class="description">

            Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone will be assigned

          </p>

          <h4>Any SUV
          </h4>

          <p class="description">Innova Xylo Ertiga any one will be assigned

          </p>

          <ul class="desc">
            <li>Maximum 4 passenger capacity for Sedan</li>
            <li>Maximum 7 passenger capacity for SUV</li>

            <li>Minimum 130km coverage for one way trip</li>
            <li> Minimum 250km coverage for round trip.</li>
            <li>One day Round trip close by 11pm at ur pickup </li>
            <li>Waiting charges Rs.150 per hour in between ur trip.</li>
            <li>Food break, tea break, & rest room no waiting chrgs.</li>
            <li>Cancellation charges Rs.300 after driver arrived.</li>
            <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
            <li>Note down starting and ending km is must.</li>
            <li> Permit charges and Driver Bata is same as quoted.</li>
            <li>Toll charges will be paid thru fastag by Driver.</li>
            <li>
              Payment  transfer by online or by cash.
            </li>
            <li>
              EOT calculated running km, Toll by fastag & rest all.
            </li>
            <li> Toll fees, interstate Permit, Hill charges pet charges waiting charges (if any) are
              extra
            </li>
            <li>For Bangalore minimum 300km per day in Round trip
            </li>
            <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</section>




<!-- MAIN START -->
<main class="wo-main overflow-hidden">
  <!-- CATEGORY START -->
  <section class="wo-main-section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <h2> About {{settings.siteName}} <em> Best Drop Taxi provider </em></h2>
            </div>
            <div class="wo-sectionhead__description">
              <p>We {{settings.siteName}} started and planned the Drop Taxi Services for easier and affordable. Over the
                decade,
                we lead the pioneer of customers expectations and have been confidently making to change the dynamics
                of the outstation taxi services market in Tamil Nadu. We are proud to be the No. 1 and growing One Way
                Taxi and Return Taxi Rental Networking in Tamil Nadu and providing the best in Hire Taxi Services in
                Tamil Nadu.</p>
              <p class="mt-3">
                To ensure the customer safety, we only enrol cars with 5 or less than 5 years old. We proudly say that
                we do not have any amateur driver; all our drivers have minimum 7+ years experience. When you book our
                services everytime, you don’t need to check on the driver, or the vehicle’s documents; instead we
                check it
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </section>

  <section class="wo-main-section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <span>See The Market Trend With</span>
              <h2>Popular Outstation <em>Drop Taxi Routes</em></h2>
            </div>
          </div>
        </div>
        <div  *ngIf="cars" class="col-12">
          <div *ngFor="let city of cities" class="wo-vehiclesinfos">
            <figure class="wo-vehicles-img">
              <img src="{{city.image}}" alt="img description">
           
            </figure>
            <div class="wo-vehiclesinfo">
             
              
              <div class="wo-vehiclesinfo__title">
                <h3 style="text-transform: capitalize;"><a href="#booking">{{city.origin}} to {{city.destination}}</a></h3>
                
              </div>

              <table style="width:100%" colspan="2" class="taff">
                <tbody>
                    <tr>
                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">SEDAN</span>
                        </td>

                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">Rs.{{city['SEDAN']}}</span>
                           
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">ETIOS</span>
                        </td>

                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">Rs.{{city['ETIOS']}}</span>
                           
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">SUV</span>
                        </td>

                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">Rs.{{city['SUV']}}</span>
                           
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">INNOVA</span>
                        </td>

                        <td>
                            <span style="display: block;padding-left: 22px;font-weight: bold;">Rs.{{city['INNOVA']}}</span>
                           
                        </td>
                    </tr>
                </tbody>
            </table>

            
            </div>
            <div class="wo-contectinfo">
              <div class="wo-contectinfo__price">
                <span>Book Chennai to Madurai Cabs at lowest price {{city['SEDAN'] }} from {{settings.siteName}}. Get best deals for
                  {{city.origin}} to
                  {{city.destination}} car rentals. Compare from variety of car types like Sedan, Suv</span>

                <div class="wo-header-btns" style="
                margin-left: 0px;
            ">
                  <a href="tel:{{settings.phoneNo}}" class="wo-contectnum"><i class="ti-mobile"></i>Call Now</a>
                  <a href="https://wa.me/{{settings.whatsapp}}" class="wo-btn wp">
                    <i class="fab fa-whatsapp"></i>Whatsapp Us</a>
                </div>

                <a href="#booking" class="wo-btn">Book Now</a>

              </div>
            </div>
          </div>

          <div class="wo-sectionbtns">
            <a routerLink="/outstation-cabs" class="wo-btn">Browse All</a>
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
<!-- MAIN END -->