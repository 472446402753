import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutstationService {

  routeDetails = new BehaviorSubject(null);
  cars= new BehaviorSubject(null);
  appSettings = new BehaviorSubject(null);
  tariff = new BehaviorSubject(null);

  constructor() { }
}
