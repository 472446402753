<mat-horizontal-stepper linear #stepper>
  <mat-step>

    <form [formGroup]="firstFormGroup" class="wo-themeform wo-bannerform">
      <fieldset>
        <label class="wo-titleinput one">I’m Looking For:</label>

        <div class="wo-radioholder">
          <span class="wo-radio">
            <input type="radio" id="Buy" value="One Way" checked="" formControlName="tripType">
            <label for="Buy">
              <span><i class="far fa-circle"></i>One Way</span>
            </label>
          </span>
          <span class="wo-radio">
            <input type="radio" id="Rent" value="Round Trip" formControlName="tripType">
            <label for="Rent">
              <span><i class="far fa-circle"></i>Round Trip</span>
            </label>
          </span>
        </div>

        <div class="date-wrap">
          <div class="form-group">
            <label class="wo-titleinput">Name</label>
            <div class="wo-form-icon">
              <input class="form-control" formControlName="name" placeholder="Full name">
            </div>
            <mat-error *ngIf="submitted && contactValidation.name.errors">
              Name is <strong>required</strong>
            </mat-error>
          </div>

          <div class="form-group">
            <label class="wo-titleinput">Mobile No</label>
            <div class="wo-form-icon">
              <input class="form-control" formControlName="phone" placeholder="Mobile number">

            </div>
            <mat-error *ngIf="submitted && contactValidation.phone?.errors?.required">
              Mobile number is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="submitted && contactValidation.phone?.errors?.pattern">
              Enter valid <strong> Mobile number </strong>
            </mat-error>
          </div>
        </div>



        <div class="form-group">
          <label class="wo-titleinput">From</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Chennai" required="" #origin>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>

        </div>
        <div class="form-group">
          <label class="wo-titleinput">To</label>
          <div class="wo-form-icon">
            <input type="text" class="form-control" placeholder="Start typing city - e.g. Bangalore" required=""
              #destination>

            <a class="wo-right-icon"><i class="ti-target"></i></a>
          </div>

        </div>

        <div class="date-wrap">
          <div class="form-group">
            <label class="wo-titleinput">Pickup Date</label>
            <div class="wo-form-icon">
              <input class="form-control" formControlName="pickupDate" placeholder="Pickup date"
                formControlName="pickupDate" [min]="minDate" (click)="picker.open()" [matDatepicker]="picker" readonly>

              <mat-datepicker #picker></mat-datepicker>

              <a class="wo-right-icon"><i class="ti-calendar"></i></a>
            </div>
            <mat-error *ngIf="submitted && contactValidation.pickupDate?.errors?.required">
              Pickup Date is <strong>required</strong>
            </mat-error>
          </div>
          <div class="form-group">
            <label class="wo-titleinput">Pickup Time</label>
            <div class="wo-form-icon">
              <mat-select class="form-control time-select" formControlName="pickupTime" placeholder="Time">
                <mat-option *ngFor="let time of times" [value]="time">
                  {{time}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
                Pickup Time is <strong>required</strong>
              </mat-error>

              <a class="wo-right-icon"><i class="ti-timer"></i></a>
            </div>
            <mat-error *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
              Pickup Time is <strong>required</strong>
            </mat-error>
          </div>
        </div>

        <div *ngIf="contactValidation.tripType.value === 'Round Trip'" class="form-group">
          <label class="wo-titleinput">Return Date</label>
          <div class="wo-form-icon">
            <input [min]="contactValidation.pickupDate.value" (focus)="picker1.open()" [matDatepicker]="picker1"
              matInput placeholder="Retun date" formControlName="returnDate" readonly>
            <mat-datepicker #picker1></mat-datepicker>

            <a class="wo-right-icon"><i class="ti-calendar"></i></a>
          </div>
        </div>

        <div class="form-group">
          <label class="wo-titleinput">Please select your cab type</label>
          <div class="carbox">
            <div *ngFor="let car of cars" class="col">
              <div class="cars-item" (click)="selectedCarType(car)">
                <label class="wo-titleinput">{{ contactValidation.tripType.value !== 'Round Trip' ?
                  car.oneway : car.roundtrip}} ₹
                  / Km</label>
                <img *ngIf="car.carType !== selectedCarName" src="{{car.image}}">
                <img *ngIf="car.carType === selectedCarName" src="{{car.activeImage}}">
                <label class="wo-titleinput">{{car.carType}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group wo-form-btns">
          <button (click)="requestAride()" type="submit" class="wo-btn">
            <span>Get Set Go</span>
          </button>
        </div>
      </fieldset>
    </form>

    <div class="hide-map">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
        <ng-container *ngIf="showDirection">
          <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
          </agm-direction>
        </ng-container>
      </agm-map>
    </div>

  </mat-step>
  <mat-step>
    <div *ngIf="show">
      <h2 class="text-center">Trip Estimation <span style="color:#ed463c ;">Rs.{{bookingDetails.tripEstimation}}
        </span></h2>

      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Total Distance :</td>
            <td>{{bookingDetails.distance}} KM</td>
          </tr>

          <tr>
            <td>Total Duration</td>
            <td>{{bookingDetails.totalDuration}}</td>
          </tr>
          <tr>
            <td>Rate Per Km</td>
            <td>Rs. {{bookingDetails.ratePerKM}}</td>
          </tr>
          <tr *ngIf="bookingDetails.tripType === 'Round Trip'">
            <td>No of Days :</td>
            <td>{{this.days}}</td>
          </tr>
          <tr>
            <td>Selected Car Type</td>
            <td>{{bookingDetails.carType}} </td>
          </tr>

          <tr>
            <td>Driver Allowance :</td>
            <td>Included</td>
          </tr>
        </tbody>
      </table>
      <h5 style="margin-top: 10px;" class="text-center">Note <span style="color:#ed463c;"> Toll Gate and State Permit
          are extra </span></h5>


      <div class="row">
        <div class="col-xs-12 text-center">

          <button (click)="confirm()" type="submit" class="wo-btn">
            <span>Confirm Booking</span>
          </button>
        </div>
      </div>

    </div>

  </mat-step>


  <mat-step>
    <div *ngIf="bookingDetails" class="confirmationText">

      <div class="wo-sectionhead__title">
        <h2>Thank You, <em>{{bookingDetails.name}}</em> !</h2>
      </div>

      <p class="bookingId">Your Booking (ID: <span class="reservation-id">{{bookingDetails.bookingId}}</span>) is
        confirmed.</p>

      <p class="bookingMessage">The reservation details have been sent to your email
        ID and phone. You will receive your driver details
        2 hours prior to your pick up time. </p>

      <p class="bookingId" style="margin-top: 10px;">In these unprecedented times,
        {{settings.siteName}} is pleased to provide a comprehensive yet cost-effective rides. For
        details please contact <a href="tel:{{settings.phoneNo}}"> {{settings.phoneNo}} </a> </p>
    </div>
  </mat-step>
</mat-horizontal-stepper>