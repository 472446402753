export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBwGUkw3vHaS6NcgE9ybsAlsvKoNNDVEts",
    authDomain: "droptaxi-salem.firebaseapp.com",
    databaseURL: "https://droptaxi-salem-default-rtdb.firebaseio.com",
    projectId: "droptaxi-salem",
    storageBucket: "droptaxi-salem.appspot.com",
    messagingSenderId: "673390675462",
    appId: "1:673390675462:web:cd2b18145e6d751432a587"
  }
};
