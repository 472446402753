import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CARS } from './cars';
import { PLACES, popular_cities } from './places';
import { SITE_SETTINGS } from './site-settings';
import { OutstationService } from './outstation.service';
import firebase from 'firebase';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'nonstop';

  places = PLACES;
  cars;
  popularCities = popular_cities;
  settings = SITE_SETTINGS;

  constructor(private http: HttpClient,
    private taxiService: OutstationService
  ) { }

  ngOnInit() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }
    this.getTariff()
  }

  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });

        console.log(tariff)
        this.taxiService.tariff.next(tariff);
      }
    });
  }



}



