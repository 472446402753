<div class="wo-aboutbannerwrap">
    <div class="wo-aboutbannerbg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 596.99">
            <path class="path1" d="M1923,0V456.68C963,456.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
            <path class="path2" d="M1923,0V436.68C963,436.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="wo-aboutbanner">
                    <div class="wo-aboutbanner__title">
                        <h2>Privacy Policy for Drop Taxi Salem</h2>
                    </div>
                    <div class="wo-aboutbanner__description">

                    

                        <p>Thank you for choosing Drop Taxi Salem for your transportation needs. This Privacy Policy is
                            designed to help you understand how we collect, use, disclose, and safeguard your personal
                            information. By using our services, you agree to the terms outlined in this policy.</p>

                        <h2>Information We Collect:</h2>

                        <ol>
                            <li>
                                <strong>Personal Information:</strong>
                                <ul>
                                    <li>Contact details such as name, phone number, and email address.</li>
                                    <li>Payment information for processing transactions.</li>
                                    <li>Pickup and drop-off locations.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Usage Information:</strong>
                                <ul>
                                    <li>Information about how you use our services.</li>
                                    <li>Device information, such as device type, operating system, and IP address.</li>
                                    <li>Log data, including date, time, and duration of service.</li>
                                </ul>
                            </li>
                        </ol>

                        <h2>How We Use Your Information:</h2>

                        <p>1. <strong>Service Provision:</strong> To facilitate your taxi bookings and provide
                            transportation services. To communicate with you regarding your bookings, updates, and
                            promotions.</p>
                        <p>2. <strong>Payment Processing:</strong> For processing payments and preventing fraudulent
                            activities.</p>
                        <p>3. <strong>Improving Services:</strong> To analyze and improve the quality of our services.
                            To customize and enhance your experience.</p>
                        <p>4. <strong>Legal Compliance:</strong> To comply with applicable laws, regulations, or legal
                            processes.</p>

                        <!-- Add more sections as needed -->

                        <h2>Information Sharing:</h2>

                        <p>1. <strong>Third-Party Service Providers:</strong> We may share information with third-party
                            service providers who assist in providing our services, such as payment processors and
                            mapping services.</p>
                        <p>2. <strong>Legal Obligations:</strong> We may disclose information to comply with legal
                            obligations, such as responding to subpoenas or court orders.</p>
                        <p>3. <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all
                            or a portion of our assets, your information may be transferred as part of the transaction.
                        </p>

                        <!-- Add more sections as needed -->

                        <h2>Data Security:</h2>

                        <p>We take reasonable measures to protect your information from unauthorized access, disclosure,
                            alteration, and destruction. However, please note that no method of transmission over the
                            internet or electronic storage is 100% secure.</p>

                        <h2>Your Choices:</h2>

                        <p>You have the right to:</p>
                        <ul>
                            <li>Access and update your personal information.</li>
                            <li>Opt-out of marketing communications.</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>